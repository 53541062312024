import { applyMiddleware, createStore } from 'redux'
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';


const initialState = {
  sidebarShow: 'responsive',
  userProfile: {}
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      console.log('sett new store', {...state} , {...rest});
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState, composeWithDevTools(applyMiddleware(thunk)))
export default store